.news-app {
    display: flex;
    justify-content: center;
}

.news-item {
    width: 500px;
    padding: 20px;
    border: 1px solid;
    margin-bottom:20px;
}

.news-img {
    width: 400px;
}