.application-page-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.why-us-container {
    display:flex;
    flex-direction: column;
    margin-top: 125px;
    margin-bottom: 125px;
    width: 500px;
    padding: 20px;
    border: 1px solid;
    margin-bottom:20px;
}

.current-offerings {
    margin-top: 50px
}

.job-offer-entry {
    display:flex;
    flex-direction: column;
    margin-bottom: 125px;
    width: 500px;
    padding: 20px;
    border: 1px solid;
    margin-bottom:20px;
}